#dateSelect {
  display: flex;
  flex-direction: column;
  background: $gray-200;
  align-items: center;
  padding: 5em 0 0;
  h2 { margin-bottom: 0.5em; }
  form {
    margin: 0 auto 3em;
    .btn-primary {
      margin: 1em auto;
    }
  }
  .tour-list {
    align-self: bottom;
    .table {
      border-radius: 1em 1em 0 0;
      margin-bottom: 0; 
      thead {
        tr { background: $green; }
        color: white;
      }
      tr {
        background: white; 
        td:first-child { text-align: left; }
      }
    }
  }
}