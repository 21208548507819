// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.jumbotron {
    border-radius: 0;
    position: relative;
    padding: 5rem 0;
    margin-bottom: 0;
    position: relative;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-up(lg) { height: 40vh; }

    .feature-tag {
        background: rgba($orange, 0.8);
        color: white;
        display: inline-block;
        padding: 1em;
        border-radius: 0 0.5em 0.5em 0;
        position: absolute;
        top: 5px;

        h3 {
            font-size: 1rem;
            margin-bottom: 0; 

            i { margin-right: 0.5rem; }
        }
    }
    .container {
        text-align: center;
        color: white;
        text-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
        hr {
            width: 2em;
            border-top: 2px solid white;
        }
        a { color: white; }
    }
    .interior & {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
        text-align: left;
    }
}
.info {
    width: 100%;
    margin: 0;
    background: rgba($gray-900, 0.8);
    color: white;
    .col-sm-4, .col-sm-12, .col-sm-6 {
        padding: 1em 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid $green;

        p { margin-bottom: 0; }
    }
}

.breadcrumb {
    border-radius: 0;
    background-color: white;
    justify-content: center;
}
