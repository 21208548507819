.slick-slider {
	overflow: hidden;
	.slick-list {
		.slick-track {
			display: flex !important;
			.slick-slide {
				height: inherit !important;
				.row {
					height: 100% !important;
					.col-md-6 {
						height: 100% !important;
					}
				}
			}
		}
	}
	.slider-button {
		position: absolute;
		color: white;
		z-index: 200;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		top: 0;
		background: rgba(black, 0);
		font-size: 3rem;
		transition: background 0.3s ease-in-out;
		&.right { right: 0; }
		&:hover,
		&:focus { background: rgba(black, 0.5); }
	}
}