// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
	&.btn-primary {
		background-color: $green; 
		border-color: $green;
		&:hover,
		&:focus {
			background-color: white;
			border-color: white;
			color: $green;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
		}
	}
	&.btn-outline-primary {
		border-color: white;
		color: white;
		&:hover, &:focus {
			background-color: white;
			border-color: white;
			color: $green;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
		}
	}
	&.btn-link {
		color: $green;
		&:hover,
		&:focus { 
			color: lighten($green, 10%);
		}
	}
}

.page-item {
	.page-link {
		background: $green;
		color: white;
	}
	&.disabled {
		.page-link {
			background: $gray-400; 
			color: white;
		}
	}
}