main.search {
	.page-content {
		form {
			.input-group {
				.form-control {

				}
				.input-group-btn {
					.btn {
						margin: 0;
						padding: 0.125rem 0.3125rem;
						border: 1px solid #999;
					}
				}
			}
		}
		.search-result {
			margin: 0 0 0.625rem 0;
			padding: 0 0 1.25rem 0;
			border-bottom: 1px solid #ccc;
			.search-page-title {
				font-size: 1.25rem;
				margin-bottom: 0;
				text-transform: initial;
				@media (max-width: 767.9px) {
					font-size: 1.5rem;
				}
				a {
					color: black;
					&:hover {
						color: $green;
					}
				}
			}
			.search-page-desc {
				font-size: 0.75rem;
				margin-bottom: 0;
				@media (max-width: 767.9px) {
					font-size: 1.0rem;
				}
			}
			.search-page-link {
				font-size: 0.8125rem;
				text-decoration: underline;
				@media (max-width: 767.9px) {
					font-size: 1.125rem;
				}
			}
		}
	}
}

#modal-search-fs {
	.modal-dialog {
		.modal-content {
			margin: 0;
			padding: 0.125rem 0.625rem 0.625rem 0.625rem;
			.modal-header {
				margin: 0;
				padding: 0;
				border-bottom: none;
				.close {
					font-size: 2.0rem;
				}
			}
			.modal-body {
				margin: 0;
				padding: 0.625rem 0 0 0;
				.input-group {
					.form-control {

					}
					.input-group-btn {
						.btn {
							margin: 0;
							padding: 0.125rem 0.3125rem;
							border: 1px solid #999;
						}
					}
				}
			}
		}
	}
}
