#modal-request {
		font-size: .9rem;

		.form-control {
				font-size: .9rem;
		}

		.modal-dialog {
				max-width: 800px;
				margin: 1.75rem auto;
		}
}

.modal {
		z-index: 99999996;
}

.modal-backdrop.show {
		z-index: 99999995;
}

.contact-form-container {
		margin-bottom: 10rem;
}

.ctct-inline-form {
	font-family: $font-body1;
	.ctct-form-container {
		.ctct-form-defaults {
			.ctct-form-custom {
				.ctct-form-header {
					font-family: $font-heading1;
					font-weight: 700;
				}
				.ctct-form-label {
					font-family: $font-body1;
					font-weight: 700;
				}
				.ctct-form-required {
					&:before {
						color: $orange;
					}
				}
				.ctct-form-button {
					font-family: $font-heading1;
					font-weight: 400;
					font-size: 1.5rem;
					background-color: $green; 
					border-color: $green;
					&:hover,
					&:focus {
						background-color: white;
						border-color: $green;
						color: $green;
					}
				}
			}
		}
	}
}
