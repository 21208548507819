#blog {
  background: $gray-800;
  color: white;
  margin: 0;
  .col-md-6:first-child {
    .card {
      @include media-breakpoint-up(md){
        margin-right: 0.5em; 
      }
    }
  }
  .col-md-6:nth-child(2){
    .card:first-child{
      @include media-breakpoint-up(md) {
        margin-right: 1em;        
      }
    }
  }
  .card {
    border-radius: 0;
    border: none;
    position: relative;
    height: 10em;
    z-index: 0;
    margin: 0.3em 0;
    @include media-breakpoint-up(md){ height: 5em; }
    &.lg {
      height: 15em; 
      @include media-breakpoint-up(md) { height: 20em; }
}
    @include media-breakpoint-up(md) { height: 10em; }
    .card-body {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-end;
      align-items: left;
      color: white;
      .card-title, p {
        transform: translateY(1em);
        transition: transform 0.2s ease-in;
      }
      .card-title{ font-size: 1rem; }
      p { font-size: 0.8rem; }
      .btn-primary {
        max-width: 80%;
        opacity: 0;
        transition: opacity 0.2s ease-in;
      }
    }
    .card-overlay {
      position: absolute;
      background: rgba($black, 0.6);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover,
    &:focus {
      .card-title, p {
        transform: translateY(0);
      }
      .btn-primary {
        opacity: 1;
      }
      .card-overlay {
        opacity: 1;
      }
    }
  }
}

.pagination {
  margin-top: 1em !important;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}