// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
section {
	padding: 3em 0;
	margin: 2em 0;
}

main {
	.about {
		padding: 2em 1em 1em;
	}
}