.locations {
  background: $gray-100;
  margin: 0 0 1em;
  .date-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      button {
        margin: 1em auto 0; 
        @include media-breakpoint-up(md){ margin: 0; }
      }
    }
  }
  .card {
    margin-bottom: 2em;
    color: white;
    text-align: center;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      .card-title {
        transform: translateY(1em);
        transition: transform 0.3s ease-in-out;
        font-size: 1rem;
      }
      a {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }
    .card-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(black, 0.6);
      z-index: -1;
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
    }
    &:hover,
    &:focus {
      .card-title { transform: translateY(0); }
      a { opacity: 1; }
      .card-overlay { opacity: 1; }
    }
  }
}