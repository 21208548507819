// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
body{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	main{
		flex: 1 0 auto;
		margin: 0;
		.page-content { padding: 4em 2em; }
		&.specials {
			.page-content { display: none; }
		}
	}
	.mfp-close { color: white !important; }
	.mfp-bg { z-index: 9999995 !important; }
	.mfp-wrap { z-index: 9999996 !important; }
}
