//Text Color Options in Redactor
.color-brandcolor1 {
	color: $green;
}

.color-brandcolor2 {
	color: $orange;
}

.color-brandcolor3 {
	color: $beige;
}

.color-red {
	color: #c40202;
}

.color-blue {
	color: #0202c4;
}

.color-gray-mid2 {
	color: #999999;
}


//Text Style Options in Redactor
.font-heading1 {
	font-family: $font-heading1;
}
.font-body1 {
	font-family: $font-body1;
}