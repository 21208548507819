.preloader-wrapper {
    background: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999999;
    padding: 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.5s ease-out;
    .loaded & {
        opacity: 0;
        z-index: -100;
    }
    .progress {
        position: relative;
        height: 4px;
        display: block;
        width: 100%;
        background-color: lighten($green, 40%);
        border-radius: 2px;
        margin: 0 ;
        overflow: hidden;
        .determinate {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: $green;
            transition: width .3s linear;
        }
        .indeterminate {
            background-color: $green;
            &:before {
                content: '';
                position: absolute;
                background-color: inherit;
                top: 0;
                left: 0;
                bottom: 0;
                will-change: left, right; // Custom bezier
                animation: indeterminate 2.1s cubic-bezier(0.650, 0.815, 0.735, 0.395) infinite;
            }
            &:after {
                content: '';
                position: absolute;
                background-color: inherit;
                top: 0;
                left: 0;
                bottom: 0;
                will-change: left, right; // Custom bezier
                animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite;
                animation-delay: 1.15s;
            }
        }
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}