main.destinations, main.usa, main.europe, main.canada, main.central-america, main.spring, main.summer, main.fall, main.winter, main.uncategorized-tours {
	.page-content { display: none; }
	.card {
		margin-bottom: 2em;

		.card-img-top {
			height: 12em;
			position: relative;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				margin: 0;
				padding: 0;
				width: inherit;
				height: inherit;
				// position: absolute;
				// left: 50%;
				// top: 50%;
				// transform: translate(-50%, -50%);
				object-fit: cover;
				object-position: center;
			}
			.card-img-overlay {
				background: rgba(black, 0.2);
				color: white;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
		.list-inline {
			margin: 0;
			padding: 1em 0.625em;
			background: $gray-900;
			color: white;
			display: flex;
			justify-content: space-around;
		}
		.card-body {
			height:7em;
		}
		.card-footer{
			background-color: white;
			border-top: none;
		}
	}
}

.destination-detail {
	.intro {
		background: $gray-100;
		padding: 2em 0;
	}
	.nav {
		flex-wrap: nowrap;
		overflow-x: auto;
		border-top: 1px solid $green;
		border-bottom: 1px solid $green;
		background: $white;
		.nav-link{
			color: $gray-700;
			padding: 1rem;
			border-radius: 0;
			white-space: nowrap;
			&:hover,
			&:focus {
				background: $green;
				color: white;
			}
			&.active {
				background: $green;
				color: white;
			}
		}
	}
	.tab-content {
		border-left: 1px solid $gray-300;
		border-right: 1px solid $gray-300;
		border-bottom: 1px solid $gray-300;
		padding: 3em 1em;
		margin-bottom: 4em;
		.map-img { max-width: 50%; }
		position: relative;
		top: 0;
		.tab-pane {
			position: relative;
			top: 0;
		}
	}

	.see-it {
		margin-bottom: 4em;
		.card {
			border-radius: 0;
			border: none;
			margin: 0.1em;
			.card-img-overlay {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				i { color: white; }
			}
		}
		.videos {margin-top: 5em;}
	}
	.tags {
		margin-bottom: 4em;
		.badge-primary {
			color: $green;
			border: 1px solid $green;
			background: none;
			padding: 1em 1.5em;
		}
	}
}

.sold-out-text {
	display: inline-block;
	//margin: 1.25rem;
	padding: 0.3125rem 1.25rem;
	background-color: $orange;
	color: white;
	font-size: 1.25rem;
	font-weight: bold;
}

.offline-reservation-text {
	color: white;
	a {
		text-decoration: none;
		&:hover,
		&:focus { color: lighten($green, 10%); }
	}
}

.tour-calendar {
	.page-content {
		.calendar-category-row {
			padding-bottom: 0.625rem;
			.calendar-category-container {
				margin: 0 0 0.625rem 0;
				padding: 0.3125rem;
				.calendar-category-label {
					margin: 0;
					padding: 0;
				}
			}
		}
		.calendar-container {
			.tab-panel {
				.nav-tabs {
					margin: 0 -0.625rem;
					padding: 0;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					border-bottom: none;
					.gridlist-switch-button {
						margin: 0 0 0 0.125rem;
						padding: 0.3125rem 0.625rem;
						position: relative;
						border: 1px solid #CCC;
						border-radius: 0.3125rem;
						a.gridlist-switch-button-link {
							display: flex;
							justify-content: space-between;
							align-items: center;
							text-decoration: none;
							&:hover {
								text-decoration: none;
							}
							&.active {
								.gridlist-switch-label {
									color: black;
								}
								.fa, .fac {
									color: black;
								}
							}
							.gridlist-switch-label {
								margin: 0;
								padding: 0.125rem;
								font-size: 0.625rem;
								font-weight: bold;
								line-height: 1;
								text-transform: uppercase;
								@media (max-width: 767.9px) {
									font-size: 0.8125rem;
								}
							}
							.fa, .fac {
								margin: 0;
								padding: 0.125rem;
								@media (max-width: 767.9px) {
									padding: .3125rem;
									font-size: 1.5rem;
								}
							}
						}
					}
				}
				.calendar-year-row {
					.calendar-year-container {
						margin: 0 0 0.625rem 0;
						padding: 1.25rem 0.3125rem 0.625rem 0.3125rem;
						&.first-year {
							margin-top: -3.125rem;
						}
						&:not(.first-year) {
							border-top: 2px solid black;
						}
						.calendar-year-label {
							margin: 0;
							padding: 0;
							font-size: 1.25rem;
							font-weight: 700;
							line-height: 1;
							letter-spacing: -0.125rem;
							@media (max-width: 767.9px) {
								font-size: 1.5rem;
							}
						}
					}
				}
				.calendar-month-row {
					.calendar-month-container {
						margin: 0 0 0.625rem 0;
						padding: 1.25rem 0.3125rem 0.625rem 0.3125rem;
						border-top: 2px solid #999;
						.calendar-month-label {
							margin: 0;
							padding: 0;
							color: #666;
							font-size: 1.0rem;
							font-weight: 700;
							line-height: 1;
							text-transform: uppercase;
							@media (max-width: 767.9px) {
								font-size: 1.25rem;
							}
						}
					}
				}
				.calendar-row {
					padding-bottom: 0.625rem;
					.calendar-item-container {
						padding: 0 0.3125rem 1.25rem 0.3125rem;
					}
					.calendar-item-text-row-container {
						padding: 0 0.3125rem 0 0.3125rem;
						&:not(:last-child) {
							border-bottom: 1px solid #DDD;
						}
					}
				}
			}
		}
		.aside-filter-container {
			padding: 0 0.625rem;
			@media (max-width: 767.9px) {
				padding: 0.3125rem 0;
			}
			.filter-container {
				.btn-nav-accordion-button {
					@media (max-width: 767.9px) {
						width: calc(100% - 0.625rem);
						margin: 0 0.3125rem 0.625rem 0.3125rem !important;
					}
				}
				.tour-filter-nav {
					@media (max-width: 767.9px) {
						padding: 0 0.625rem;
					}
					.filter-all {
						margin: 0;
						padding: 0 0 1.25rem 0;
						display: block;
						font-size: 0.75rem;
						line-height: 1;
						text-decoration: none;
						&:hover {
							text-decoration: none;
						}
						&:active {
							text-decoration: none;
						}
						@media (max-width: 767.9px) {
							padding: 1.0rem 0 2.5rem 0;
							font-size: 1.125rem;
						}
					}
					.list-group-title {
						margin: 0;
						padding: 0 0 0.125rem 0;
						display: block;
						font-size: 0.625rem;
						font-weight: bold;
						line-height: 1;
						text-transform: uppercase;
						//border-bottom: 1px solid black;
					}
					.list-group-flush {
						margin: 0 0 2.5rem 0;
						padding: 0;
						.list-group-item {
							margin: 0;
							padding: 0.3125rem 0;
							font-size: 0.75rem;
							line-height: 1;
							&:not(:last-child) {
								border-bottom: 1px solid #CCC;
							}
							&:last-child {
								border-bottom: none;
							}
							a {
								display: block;
								text-decoration: none;
								&:hover {
									text-decoration: none;
								}
								&:active {
									text-decoration: none;
								}
								&.active {
									//font-weight: bold;
									color: black;
								}
							}
							@media (max-width: 767.9px) {
								padding: 1.0rem 0;
								font-size: 1.125rem;
							}
						}
					}
				}
			}
		}
	}
}

.calendar-item-card {
	height: 100%;
	.card-img-top {

	}
	.card-body {
		margin: 0;
		padding: 0;
		.card-date-container {
			margin: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			background-color: black;
			.card-date {
				margin: 0;
				padding: 0.625rem;
				color: white;
				font-size: 0.8125rem;
				line-height: 1;
				@media (max-width: 767.9px) {
					font-size: 1.125rem;
				}
			}
			.calendar-card-sold-out {
				margin: 0;
				padding: 0.125rem 0.625rem;
				display: block;
				width: auto;
				height: 100%;
				background-color: $orange;
				color: white;
				font-size: 0.625rem;
				font-weight: bold;
				text-transform: uppercase;
				white-space: nowrap;
				@media (max-width: 767.9px) {
					font-size: 1.25rem;
				}
			}
		}
		.card-title-container {
			margin: 0;
			padding: 0.625rem;
			a {
				text-decoration: none;
				&:hover {
					text-decoration: none;
				}
				&:active {
					text-decoration: none;
				}
			}
			.card-title {
				font-size: 1.0rem;
				line-height: 1;
				@media (max-width: 767.9px) {
					font-size: 1.5rem;
				}
			}
			.card-subtitle {
				font-size: 0.625rem;
				line-height: 1;
				@media (max-width: 767.9px) {
					font-size: 1.0rem;
				}
			}
		}
	}
}

.calendar-text-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 767.9px) {
		padding: 0.625rem 0;
	}
	.card-date-container {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.card-date {
			margin: 0;
			padding: 0.625rem;
			color: black;
			font-size: 0.8125rem;
			line-height: 1;
			@media (max-width: 767.9px) {
				font-size: 1.125rem;
				padding: 0.625rem 0;
			}
		}
		.calendar-card-sold-out {
			margin: 0;
			padding: 0.125rem 0.3125rem;
			display: block;
			width: auto;
			//height: 100%;
			background-color: $orange;
			color: white;
			font-size: 0.625rem;
			font-weight: bold;
			text-transform: uppercase;
			white-space: nowrap;
			@media (max-width: 767.9px) {
				font-size: 1.25rem;
			}
		}
	}
	.card-title-container {
		margin: 0;
		padding: 0.625rem;
		@media (max-width: 767.9px) {
			font-size: 1.125rem;
			padding: 0.3125rem 0.8125rem;
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: none;
			}
			&:active {
				text-decoration: none;
			}
		}
		.card-title {
			font-size: 1.0rem;
			line-height: 1;
			margin-bottom: 0;
			@media (max-width: 767.9px) {
				font-size: 1.5rem;
			}
		}
		.card-subtitle {
			font-size: 0.625rem;
			line-height: 1;
			@media (max-width: 767.9px) {
				font-size: 1.0rem;
			}
		}
	}
}
