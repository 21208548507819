// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer,
.brand-border {
	border-top: 10px solid $green;
}
footer{
	.row { padding: 1em 0; }
	.row:first-child {
		img { width: 15em; }
	}
	.accolades {
		border-top: 1px solid lightgray;
		border-bottom: 1px solid lightgray;
	}
	.quick-links {
		text-align: center;
		word-break: break-word;
		h4 {
			font-size: 11px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			color: $gray-700;
			padding: 10px 0 0 0;
			@media (max-width: 768px) {
				padding: 30px 0 0 0 !important;
			}
		}
		ul > li > a {
			font-size: 15px;
			color: $green;
			&:hover,
			&:focus {
				color: $green;
			}
		}
		ul > li {
			padding: 6px 0 7px 0;
			border-top: 1px solid $gray-400;
			line-height: 15px;
			@media (max-width: 768px) {
				padding: 15px 0 16px 0 !important;
			}
		}
		ul > li:last-child {
			border-bottom: 1px solid $gray-400;
		}
		ul > li:first-child {
			border-top: 1px solid $gray-700;
		}
		@include media-breakpoint-up(sm) { text-align: left; }
	}
}
