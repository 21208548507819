// ---------------------------------------------
// Basic typography style for copy text
// ---------------------------------------------
html {
  font-size: 14px;
  @include media-breakpoint-up(sm){ font-size: 16px; }
  @include media-breakpoint-up(md){ font-size: 18px; }
  @include media-breakpoint-up(lg){ font-size: 20px; }
  @include media-breakpoint-up(xl){ font-size: 20px; }
}

h1,
h2,
h3,
h4 {
  font-family: $font-heading1;
}

body,
p,
a {
  font-family: $font-body1;
}

a {
  color: $green;
  &:hover,
  &:focus { color: lighten($green, 10%); }
}