.team {
  padding: 4em 0;
  .page-content { display: none; }
  .col-md-4 {
    .team-img {
      height: 10em;
      width: 10em;
      margin: auto;
      border-radius: 50%;
    }
    .img-fluid { width: 10em; }
    h4 { color: $green; }
  }
}
.meet-our-discovery-directors {
	figure {
		display: inline-block;
		width: auto !important;
	}
}