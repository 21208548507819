#testimonials {
  background: $beige;
  .col-md-6{
    background: $beige;
    color: white;
    padding: 5em 2em;
    text-align: center;
    hr { border-color: white; }
    &:nth-child(2){
      display: none;
      @include media-breakpoint-up(md) { display: block; }
    }
  }
}